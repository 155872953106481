<template>
  <div :id="id" class="exception-list mt-2" style="width: 100%">
    <div class="exception-label">
      <div class="title mb-2">{{ $t('calendar.title_exception') }}</div>
      <b-form-checkbox v-model="baseExceptionsShow" @change="changeBaseExceptions" class="mb-2">{{ $t('calendar.show_base_calendar_exceptions') }}</b-form-checkbox>
      <!-- <b-alert dismissible fade :show="showError" variant="success" @dismissed="dismissAlert" class="mb-0 alert-box">
        <font-awesome-icon :icon="['far', 'check']"/>
        &nbsp;&nbsp;{{ alertMsg }}
      </b-alert> -->
      <div class="exception-toolbar border">
        <template v-if="!readOnly"> 
          <span  v-if="canAdd()">
            <b-popover
              :target="`BTN_ADD_${id}`"
              placement="top"
              triggers="hover"
              :content="$t('button.add')">
            </b-popover>
            <b-btn :id="`BTN_ADD_${id}`" @click="exceptionAdd"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>  
          </span>
          <span>
            <b-popover
              :target="`BTN_EDIT_${id}`"
              placement="top"
              triggers="hover"
              :content="$t('button.edit')">
            </b-popover>
            <b-btn :disabled="disableEdit" :id="`BTN_EDIT_${id}`" @click="exceptionEdit"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>        
          <span  v-if="canDelete()">
            <b-popover
              :target="`BTN_DELETE_${id}`"
              placement="top"
              triggers="hover"
              :content="$t('button.delete')">
            </b-popover>
            <b-btn :disabled="disableDelete" :id="`BTN_DELETE_${id}`" @click="exceptionDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>  
          </span>
          <span  v-if="canAdd()">
            <b-popover
              :target="`BTN_IMPORT_${id}`"
              placement="top"
              triggers="hover"
              :content="$t('button.import')">
            </b-popover>
            <b-dropdown id="ddown-offset" offset="25" no-caret>
              <template slot="button-content">
                <div  :id="`BTN_IMPORT_${id}`" class="text">
                  <font-awesome-icon :icon="['far', 'inbox-in']"/>
                </div>
              </template>
              <template>
                  <div class="dropdown-item" @click="holidayShow = true">
                    <span>{{ $t('calendar.import_lookup') }}</span>
                  </div>
              </template>
              <template>
                  <div class="dropdown-item" @click="docImportShow = true">
                    <span>{{ $t('calendar.import_from_file') }}</span>
                  </div>
              </template>
            </b-dropdown>
          </span>
        </template>
        <span>
          <b-popover
            :target="`BTN_EXPORT_${id}`"
            placement="top"
            triggers="hover"
            :content="$t('button.export')">
          </b-popover>
          <b-btn :id="`BTN_EXPORT_${id}`" @click="fileExport"><font-awesome-icon :icon="['far', 'inbox-out']"/></b-btn>  
        </span>
      </div>
    </div>
    

    <ag-grid-vue style="width: 100%;" class="ag-theme-balham exception-grid-height" id="exceptionGrid"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :rowData="rowData"
          :rowSelection="multiple? 'multiple':'single'"
          rowMultiSelectWithClick
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressContextMenu
          suppressMultiSort
          >
    </ag-grid-vue>

    <b-modal :title="$t('calendar.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(`calendar.confirmation.exception_delete${selected.length > 1?'_plural':''}`) }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <ExceptionModal :event="event" :readOnly="readOnly || eventReadOnly || event.uuId == null || (event.uuId.includes('EXCEPTION') && !canAdd()) || (!event.uuId.includes('EXCEPTION') && !canEdit())" :show.sync="exceptionShow" 
      @success="exceptionSuccess" 
      :title="exceptionTitle" 
      :baseStartHour="baseStartHour" 
      :baseEndHour="baseEndHour"/>    
      
    <HolidaySelectorModal :show.sync="holidayShow" @success="holidaySuccess"/>
    
    <!--Gantt Import Dialog -->
    <GanttImportDialog :properties="[{ value: 'name', text: $t('calendar.field.name') }, { value: 'startDate', text: $t('calendar.field.startDate') }, { value: 'endDate', text: $t('calendar.field.endDate') }, { value: 'startHour', text: $t('calendar.field.startHour') }, { value: 'endHour', text: $t('calendar.field.endHour') }, { value: 'identifier', text: $t('field.identifier') }, { value: 'working', text: $t('calendar.field.working') }]" :mode="'CALENDAR'" :show="docImportShow"
      :title="$t('vacation.button.import_document')"
      @modal-ok="docImportOk"
      @modal-cancel="docImportCancel" />
    
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { strRandom } from '@/helpers';
import { columnDefSortFunc } from '@/views/management/script/common';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';

export default {
  name: 'ExceptionList',
  components: {
    'ag-grid-vue': AgGridVue,
    ExceptionModal: () => import('@/components/modal/CalendarExceptionModal'),
    HolidaySelectorModal: () => import('@/components/modal/HolidaySelectorModal'),
    GanttImportDialog: () => import('@/components/Gantt/components/GanttImportDialog'),
    'detailLinkCellRenderer': DetailLinkCellRenderer // eslint-disable-line vue/no-unused-components
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    exceptions: {
      type: Array,
      default: () => []
    },
    calendarName: {
      type: String,
      required: true
    },
    baseStartHour: {
      type: String,
      default: null
    },
    baseEndHour: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      id: `EXCEPTION_LIST_${strRandom(5)}`,
      permissionName: "CALENDAR",
      gridOptions: null,
      gridApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
  
      disableEdit: true,
      disableDelete: true,
      disableOk: true,
      selected: [],

      exceptionId: null,
      exceptionShow: false,
      alertMsg: null,

      confirmDeleteShow: false,
      totalRecords: 0,

      baseExceptionsShow: false,
      holidayShow: false,
      event: null,
      docImportShow: false,
      lastOpenColumnMenuParams: null
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.selected.length != 1;
        const baseExceptions = event.api.getSelectedNodes().filter(d => d.data.calendarName !== self.calendarName);
        self.disableDelete = self.selected.length < 1 || baseExceptions.length !== 0;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.api
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.api.moveColumns([colKey], columnMenuColumnIndex + 1);
        }
        const cols = params.api.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.api.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.api.moveColumns([c.colId], index);
        }

        params.api.sizeColumnsToFit();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      getRowId: function(params) {
        return params.data.uuId;
      },
      onFirstDataRendered: function(event) {
        if (!event.api.isDestroyed()) {
          if(self.calendarName != null) {
            event.api.setColumnFilterModel('calendarName', { type: 'contains', filter: self.calendarName })
            .then(() => {
              if (!event.api.isDestroyed()) {
                event.api.onFilterChanged();
              }
            })
          } else {
            event.api.onFilterChanged();
          }
        }
      },
      getRowStyle(params) {
        if (params.data && params.data.calendarName !== self.calendarName) {
          return { opacity: 0.6 }
        }
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('calendar.field.name'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        checkboxSelection: function(params) {
          return !self.readOnly && params.data.calendarName === self.calendarName;
        },
        lockVisible: true,
        flex: 1,
        pinned: 'left',
        hide: false
      },
      {
        headerName: this.$t('calendar.field.startDate'),
        field: 'startDate',
        maxWidth: 100,
        hide: false
      },
      {
        headerName: this.$t('calendar.field.endDate'),
        field: 'endDate',
        maxWidth: 100,
        hide: false
      },
      {
        headerName: this.$t('calendar.field.working'),
        field: 'isWorking'
      },
      {
        headerName: this.$t('calendar.field.startHour'),
        field: 'startHour'
      },
      {
        headerName: this.$t('calendar.field.endHour'),
        field: 'endHour'
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier'
      },
      {
        field: 'calendarName',
        filter: 'agTextColumnFilter'
      }
    ];
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: true,
      menuTabs: []
    };
    this.columnDefs.sort(columnDefSortFunc);
    this.context = {
      componentParent: self
    };
  },
  mounted() {
  },
  created() {
    this.initEventObj();
  },
  beforeDestroy() {
    this.gridApi = null;
  },
  watch: {
    exceptions: function() {
      
      // Workaround for issue where the grid is empty and a new row is added.
      // we need to toggle the filter off then on again.
      this.changeBaseExceptions(!this.baseExceptionsShow);
      if(this.gridApi) {
        this.gridApi.refreshCells({ force: true });
      }
      this.$nextTick(() => {
        this.changeBaseExceptions(this.baseExceptionsShow);
      });
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    showError() {
      return this.alertMsg != null;
    },
    exceptionTitle() {
      return this.event && this.event.uuId && this.event.uuId.startsWith('EXCEPTION_NEW')? 
        this.$t('calendar.exception_title_new') : (this.event.calendarName === this.calendarName? this.$t('calendar.exception_title_edit') : this.$t('calendar.exception_title_view'));
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('calendar.grid.exception_no_data') }</span>`
    },
    eventReadOnly() {
      return 'SELECT' === this.mode || (this.event && this.event.calendarName !== this.calendarName);
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.rowData = this.exceptions;
      
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
      } else {
        this.alertMsg = this.$t('error.internal_server');
      }
      this.scrollToTop();
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    openDetail(id){
      this.event = this.gridApi.getRowNode(id).data;
      this.exceptionShow = true;
      this.alertMsg = null;
    },
    exceptionEdit() {
      if (this.gridApi.getSelectedNodes().length > 0) {
        this.event = this.gridApi.getSelectedNodes()[0].data;
        this.exceptionShow = true;
        this.alertMsg = null;
      }     
    },
    detailLinkLabel(params) {
      return `${params.data.name}`
    },
    exceptionSuccess({msg, event }) {
      this.$emit('change', event);
      this.alertMsg = msg;
    },
    holidaySuccess(payload) {
      for (const evt of payload) {
        if (this.exceptions.findIndex(e => e.name === evt.name && e.startDate === evt.date) === -1) {
          this.$emit('change', { uuId: evt.uuId, calendarName: this.calendarName, isWorking: false, startDate: evt.date, endDate: evt.date, name: evt.name });
        }
      }
    },
    exceptionAdd() {
      this.alertMsg = null;
      this.initEventObj(true);
      this.exceptionShow = true;
    },
    exceptionDelete() {
      this.confirmDeleteShow = true;
    },
    confirmDeleteOk() {
      const toDeleteIds = cloneDeep(this.selected);
      this.$emit('delete', toDeleteIds);
      this.alertMsg = this.$t(`calendar.grid.exception_delete${toDeleteIds.length > 1? '_plural':''}`);
    },
    changeBaseExceptions(newValue) {
      if(this.gridApi != null && !this.gridApi.isDestroyed()) {
        if (newValue || this.calendarName != null) {
          this.gridApi.setColumnFilterModel('calendarName', newValue? null : { type: 'contains', filter: this.calendarName })
          .then(() => {
            if (this.gridApi != null && !this.gridApi.isDestroyed()) {
              this.gridApi.onFilterChanged();
            }
          })
        } else {
          this.gridApi.onFilterChanged();
        }
      }
    },
    initEventObj(reset = false) {
      if(reset || !this.event) {
        this.event = {
          uuId: `EXCEPTION_NEW_${strRandom(5)}`,
          name: null,
          startDate: null,
          endDate: null,
          startHour: null,
          endHour: null,
          isWorking: false,
          calendarName: this.calendarName
        }
      }
    },
    processCellCallback(/** self */) {
      return function(params) {
        if (params.column.colId.indexOf('uuId') !== -1) {
          return params.node.data.name;
        }
        return params.value;
      }
    },
    fileExport() {
      const self = this;
      
      const keys = ['uuId', 'isWorking', 'startDate', 'startHour', 'endDate', 'endHour', 'identifier'];
    
      this.gridApi.exportDataAsExcel({ 
        fileName: 'Exceptions'
        , sheetName: 'Exceptions'
        , columnKeys: keys
        , rowHeight: 20
        , processCellCallback: self.processCellCallback(self)
      });
    },
    docImportOk({ items }) {
      this.docImportShow = false;
      for (const evt of items) {
        this.$emit('change', { uuId: `EXCEPTION_${strRandom(5)}`, calendarName: this.calendarName, startDate: evt.startDate, endDate: evt.endDate, startHour: evt.startHour, endHour: evt.endHour, name: evt.name, isWorking: evt.working === 'true', identifier: evt.identifier });
      }
    },
    docImportCancel() {
      this.docImportShow = false;
    }
  }
}


</script>

<style lang="scss">
  .exception-list {
    .title {
      font-size: 1.2em;
    }
    .exception-toolbar {
      .btn.btn-secondary {
        background-color: transparent;
        border-color: transparent;
        padding: 2px 6px;
        margin: 8px 3px;
        border-radius: 3.2px;
        color: var(--grid-toolbar-button);

        &:focus {
          box-shadow: none;
        }
        &:first-child {
          margin-left: 8px;
        }
      }
    }

    .alert-box {
      padding: 6px 12px;
      .close {
        padding: 7px 12px;
        font-size: 21px;
      }
    } 
  }

  .exception-grid-height {
    height: 200px;
    min-height: 200px;
  }  
  
  .exception-toolbar .dropdown-item {
    cursor: pointer;
  }
</style>