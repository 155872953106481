import { render, staticRenderFns } from "./ProjectKanbanBoardWidget.vue?vue&type=template&id=6074c82c&scoped=true&"
import script from "./ProjectKanbanBoardWidget.vue?vue&type=script&lang=js&"
export * from "./ProjectKanbanBoardWidget.vue?vue&type=script&lang=js&"
import style0 from "./ProjectKanbanBoardWidget.vue?vue&type=style&index=0&id=6074c82c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6074c82c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/janustest/.jenkins/workspace/05_Client_Vue_Release_Pipeline/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6074c82c')) {
      api.createRecord('6074c82c', component.options)
    } else {
      api.reload('6074c82c', component.options)
    }
    module.hot.accept("./ProjectKanbanBoardWidget.vue?vue&type=template&id=6074c82c&scoped=true&", function () {
      api.rerender('6074c82c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Dashboard/Widget/ProjectKanbanBoardWidget.vue"
export default component.exports