var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WidgetFrame", {
    on: {
      onResize: _vm.onResize,
      onOrientationChange: _vm.onOrientationChange,
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.$t("dashboard.kanban")) + " ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            !_vm.canView("PROJECT", ["TASK"])
              ? _c("div", { staticClass: "center-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "entity_selector.error.insufficient_permission_to_show_data"
                        )
                      ) +
                      " "
                  ),
                ])
              : !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : _vm.noStages
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("No project stages defined."),
                  ]),
                ]
              : _vm.noTasks
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("No tasks in project."),
                  ]),
                ]
              : [
                  _vm.loaded
                    ? _c("ag-charts-vue", { attrs: { options: _vm.options } })
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }